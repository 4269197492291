<template>
  <div>
    <blog-section-skeleton v-if="isLoadingPosts" />
    <blog-section
      :sectionName="$t('blogCategories.chiffresTendances')"
      :blogPosts="posts"
      v-if="!isLoadingPosts"
    />
  </div>
</template>

<script>
import BlogSection from '@/components/HomeComponents/BlogSection.vue';
import BlogSectionSkeleton from '@/components/HomeComponents/BlogSectionSkeleton.vue';

import blogStoreModule from '@/store/blog';
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils';
export default {
  components: {
    BlogSection,
    BlogSectionSkeleton,
  },
  setup() {
    const requiredStoreModules = [{ path: 'blog', module: blogStoreModule }];

    // Register module
    registerStoreModule(requiredStoreModules);

    // const formulairesByGroup = utilsService.getListFormulairesByGroup();

    // const appLocales = [
    //   { code: 'en', name: 'anglais' },
    //   { code: 'fr', name: 'francais' },
    // ];

    // return { requiredStoreModules, appLocales, formulairesByGroup };
    return { requiredStoreModules };
  },
  mounted() {
    this.loadBlog();
  },
  data() {
    return {
      secteur_id: 1,
      posts: [],
      isLoadingPosts: false,
    };
  },
  methods: {
    ...mapActions('blog', {
      action_fetchPosts: 'fetchPosts',
    }),
    loadBlog() {
      this.isLoadingPosts = true;
      this.action_fetchPosts({ type: 'stats', secteur_id: this.secteur_id })
        .then((response) => {
          this.posts = response.data.data;

          this.isLoadingPosts = false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur survenue lors de la récupération des articles.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
          this.isLoadingPosts = false;
        });
    },
  },
};
</script>
